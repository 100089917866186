body {
    font-family: Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif;
}

.pl-importer button {
    border-radius: 500px;
}

.pl-importer input {
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
}

.pl-importer input:focus,
.pl-importer input:hover,
.pl-importer input:active {
    background: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #55cc14;
}

.pl-importer .CodeMirror {
    border: 1px solid #ccc;
}

.pl-importer .CodeMirror .highlighted {
    background: #2eaa605c;
}
